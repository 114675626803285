import React from "react";
import HeaderBar from "./HeaderBar";

function Header() {
  return (
    <div className="h-screen bg-gradient-to-b from-[#1B171F] via-[#333644] to-[#fff]">
      <HeaderBar />
    </div>
  );
}

export default Header;
