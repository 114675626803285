import React from "react";
import logo from "../../assets/logo.svg";

function Footer() {
  return (
    <footer className="h-max bg-logo-base-color text-white">
      {/* Upper row */}
      <div className="flex flex-wrap justify-between mb-8 px-28 py-8">
        {/* 1st column */}
        <div className="w-full md:w-1/3 mb-4 md:mb-0">
          <h2 className="text-lg font-bold mb-4">Company Information</h2>
          <p>Information about the company and awards earned</p>
        </div>
        {/* 2nd column */}
        <div className="w-full md:w-1/3 mb-4 md:mb-0">
          <div className="flex justify-between mb-4">
            {/* Left column */}
            <div className="w-1/2 pr-2">
              <h2 className="text-lg font-bold mb-2">Services Offered</h2>
              <ul>
                <li>Service 1</li>
                <li>Service 2</li>
                <li>Service 3</li>
                {/* Add more services as needed */}
              </ul>
            </div>
            {/* Right column */}
            <div className="w-1/2 pl-2">
              <h2 className="text-lg font-bold mb-2">Quick Links</h2>
              <ul>
                <li>Link 1</li>
                <li>Link 2</li>
                <li>Link 3</li>
                {/* Add more quick links as needed */}
              </ul>
            </div>
          </div>
        </div>
        {/* 3rd column */}
        <div className="w-full md:w-1/3">
          <h2 className="text-lg font-bold mb-2">Google Maps Location</h2>
          {/* Display Google Maps location */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3063.630962694525!2d-122.08419328451389!3d37.42217317983304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f80d3b5b04fc5%3A0x5e5b450caf9b0d68!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1648478101480!5m2!1sen!2sus"
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            title="Google Maps Location"
          ></iframe>
          <h2 className="text-lg font-bold mt-4 mb-2">HQ Delhi, India</h2>
          <p>Adarsh Nagar, Majlis Park, Street-12, Delhi - 110033</p>
        </div>
      </div>

      <hr className="border-t border-[#D27C61] my-4"></hr>

      <div className="flex justify-between items-center px-28 py-8 ">
        <p className="flex items-center">
          ™ 2024{" "}
          <span
            className="text-md font-semibold flex items-center mx-4"
            style={{ fontFamily: "logoFontLight", letterSpacing: "2px" }}
          >
            <img src={logo} alt="Logo" className="h-6 mr-2" />
            PROGRAMMERS STUDIO.
          </span>
          All Rights Reserved.
        </p>

        <div className="flex space-x-4">
          <a href="#" className="text-white">
            Twitter
          </a>
          <a href="#" className="text-white">
            Facebook
          </a>
          <a href="#" className="text-white">
            Instagram
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
