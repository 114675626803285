import React, { useState, useEffect, useRef } from "react";
// import logo from "../../assets/logo.svg";
// import logo from "../../assets/logo_with_text.svg";
import logo from "../../assets/logo_with_text_transparent.svg";

function HeaderBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNestedDropdownOpen, setIsNestedDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNestedDropdownClick = () => {
    setIsNestedDropdownOpen(!isNestedDropdownOpen);
  };

  return (
    <div
      className={`fixed w-full z-50 transition duration-500 ease-in-out ${
        isScrolled ? "bg-logo-base-color" : "bg-transparent"
      }`}
    >
      <nav className="flex items-center justify-between px-6 py-4">
        <div className="flex items-center">
          <img className="h-16" src={logo} alt="programmers studio" />
          {/* <h1
            className="text-white ml-2 text-md font-semibold text-center"
            style={{ fontFamily: "logoFontLight", letterSpacing: "5px" }}
          >
            PROGRAMMERS
            <br />
            STUDIO
          </h1> */}
        </div>
        <div className="md:hidden">
          <button id="menu-toggle" className="text-white focus:outline-none">
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 11H3v-1h18v1Zm0 5H3v-1h18v1Zm0-10H3V5h18v1Z"
              />
            </svg>
          </button>
        </div>
        <div className="hidden md:flex items-center space-x-16 text-xs">
          <div className="relative" ref={dropdownRef}>
            <button
              className="text-white flex items-center focus:outline-none"
              onClick={handleDropdownClick}
            >
              SERVICES
              {!isDropdownOpen ? (
                <svg
                  className="h-8 w-8 text-red-500"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  style={{ height: "20px" }}
                >
                  {" "}
                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              ) : (
                <svg
                  className="h-8 w-8 text-red-500"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  style={{ height: "20px" }}
                >
                  {" "}
                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                  <polyline points="6 15 12 9 18 15" />
                </svg>
              )}
            </button>
            {isDropdownOpen && (
              <ul className="absolute top-14 left-0 bg-white shadow-lg py-1 rounded-md transition-opacity duration-300 ease-out opacity-100">
                <li className="px-4 py-2" onClick={handleNestedDropdownClick}>
                  Service 1
                </li>
                <li className="px-4 py-2">Service 2</li>
                <li className="px-4 py-2">Service 3</li>
              </ul>
            )}
            {isNestedDropdownOpen && (
              <ul className="absolute top-14 left-full bg-white shadow-lg py-1 rounded-md transition-opacity duration-300 ease-out opacity-100">
                <li className="px-4 py-2">Nested Option 1</li>
                <li className="px-4 py-2">Nested Option 2</li>
                <li className="px-4 py-2">Nested Option 3</li>
              </ul>
            )}
          </div>
          <a href="#" className="text-white">
            PRODUCTS
          </a>
          <a href="#" className="text-white">
            ABOUT
          </a>
          <a href="#" className="text-white">
            CAREER
          </a>
          <a href="#" className="text-white">
            BLOG
          </a>
          <a href="#" className="text-white">
            CONTACT US
          </a>
        </div>
      </nav>
    </div>
  );
}

export default HeaderBar;
